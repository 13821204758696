const autoNgTemplateLoaderTemplate1 = require('./access-tokens-datatable.html');

import angular from 'angular';
import controller from './access-tokens-datatable.controller';

angular.module('portainer.app').component('accessTokensDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    removeAction: '<',
  },
});
