const autoNgTemplateLoaderTemplate1 = require('./registry-form-ecr.html');

angular.module('portainer.app').component('registryFormEcr', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    model: '=',
    formAction: '<',
    formActionLabel: '@',
    actionInProgress: '<',
  },
});
